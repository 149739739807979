
@import-normalize ;

.bubbleNotifications::before {
    content: "";
    background-color: #EB5B27;
    position: absolute;
    top: -6px;
    left: -6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
